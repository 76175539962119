import { useState } from 'react';
import axios from 'axios';

export enum STATUS {
  initial = 'initial',
  loading = 'loading',
  success = 'success',
  error = 'error',
  mailSent = 'mail_sent',
}

export const useFormSubmit = (url: string, cb: () => void) => {
  const [status, setStatus] = useState<STATUS>(STATUS.initial);
  const submit = async (formData: Record<string, string>) => {
    if (status === STATUS.loading) return;

    setStatus(STATUS.loading);
    try {
      const params = new URLSearchParams(formData);
      const { data } = await axios.post(url, params.toString());
      if (data?.status === STATUS.mailSent) {
        setStatus(STATUS.success);
        cb();
      } else {
        setStatus(STATUS.error);
      }
    } catch (e) {
      console.error(e);
      setStatus(STATUS.error);
    }
  };

  return { setStatus, status, submit };
};

export default useFormSubmit;
