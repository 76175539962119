import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import {
  PRIVACY_MAIL_LINK,
  PRIVACY_MAIL,
  ROUTES,
  MINEBEST_URL,
  CONTACT_MAIL_LINK,
} from 'constants/common';

import ContactForm from 'components/ContactForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import {
  Wrapper,
  FormWrapper,
  TextWrapper,
  InfoWrapper,
  DataProcessingWrapper,
} from './styled';

const recaptchaKey = process.env.GATSBY_RECAPTCHA_SITEKEY;

const ContactData = () => {
  const {
    contact: {
      contact_form_info: { contact, dataInfo, findOutMore, header, linkName },
    },
    footer: {
      footer: { email, website },
    },
  } = useStaticQuery(graphql`
    query ContactForm {
      contact: wpPage(slug: { eq: "contact" }) {
        contact_form_info {
          contact
          dataInfo
          findOutMore
          header
          linkName
        }
      }

      footer: wpPage(slug: { in: "footer" }) {
        footer {
          contactUs
          copyright
          email
          privacyPolicy
          website
        }
      }
    }
  `);

  return (
    <Wrapper>
      <FormWrapper>
        <TextWrapper>
          <h1>{header}</h1>
          <InfoWrapper>
            <a href={MINEBEST_URL}>{website}</a>
            <a href={CONTACT_MAIL_LINK}>{email}</a>
          </InfoWrapper>
        </TextWrapper>

        <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
          <ContactForm />
        </GoogleReCaptchaProvider>
      </FormWrapper>

      <DataProcessingWrapper>
        <p>{dataInfo}</p>
        <p>
          {contact} <a href={PRIVACY_MAIL_LINK}>{PRIVACY_MAIL}</a>.
        </p>
        <p>
          {findOutMore}&nbsp;
          <Link to={ROUTES.privacyPolicy}>{linkName}</Link>.
        </p>
      </DataProcessingWrapper>
    </Wrapper>
  );
};

export default ContactData;
