import React from 'react';
import { navigate } from '@reach/router';

import { useFormValidation, useFormSubmit } from 'hooks';
import { STATUS } from 'hooks/useFormSubmit';
import { ROUTES } from 'constants/common';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Button } from 'components/UI/Button';

import {
  Wrapper,
  ButtonWrapper,
  Form,
  FormTitle,
  InputsWrapper,
  StyledInput,
  SuccessWrapper,
} from './styled';

const pattern = '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$';
const CONTACT_FORM_URL = process.env.GATSBY_CONTACT_US_FORM_URL as string;

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const map = {
    name: {
      value: '',
    },
    email: {
      value: '',
    },
    subject: {
      value: '',
    },
    message: {
      value: '',
    },
  };
  const { validityMap, checkInputs, onChange } = useFormValidation(map);

  const { name, email, subject, message } = validityMap;

  const formData = {
    username: name.value,
    email: email.value,
    subject: subject.value,
    message: message.value,
  };

  const { setStatus, status, submit } = useFormSubmit(CONTACT_FORM_URL, () => {
    navigate(ROUTES.messageSent, {
      replace: true,
    });
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha();

    if (!token) {
      return;
    }

    submit({
      ...formData,
      _wpcf7_recaptcha_response: token,
    });
  };

  const resetForm = () => {
    setStatus(STATUS.initial);
  };

  return (
    <Wrapper>
      <Form onSubmit={onSubmit}>
        <FormTitle>
          Contact <span>us</span>
        </FormTitle>
        <InputsWrapper>
          <StyledInput
            type="text"
            name="name"
            placeholder="Name *"
            required
            isValid={validityMap['name'].valid}
            onChange={onChange}
          />
          <StyledInput
            pattern={pattern}
            type="email"
            name="email"
            placeholder="Email *"
            required
            isValid={validityMap['email'].valid}
            onChange={onChange}
          />
          <StyledInput
            type="text"
            name="subject"
            placeholder="Subject *"
            minLength={5}
            required
            isValid={validityMap['subject'].valid}
            onChange={onChange}
          />
          <StyledInput
            as="textarea"
            name="message"
            placeholder="Message *"
            rows={3}
            required
            isValid={validityMap['message'].valid}
            onChange={onChange}
          />
        </InputsWrapper>
        {status !== STATUS.error && (
          <ButtonWrapper>
            <Button disabled={status === STATUS.loading} onClick={checkInputs}>
              Send
            </Button>
          </ButtonWrapper>
        )}
      </Form>

      {status === STATUS.error && (
        <SuccessWrapper>
          <ButtonWrapper>
            <Button onClick={resetForm}>Try again</Button>
          </ButtonWrapper>
        </SuccessWrapper>
      )}
    </Wrapper>
  );
};

export default ContactForm;
