import React, { useState } from 'react';

interface Validity {
  [key: string]: {
    value: string;
    valid?: boolean;
  };
}

export const useFormValidation = (validationMap: Validity) => {
  const [validityMap, setValidityMap] = useState<Validity>(validationMap);

  const checkInputs = () => {
    let map = {};
    Object.keys(validityMap).forEach((key) => {
      if (validityMap[key].value.length === 0)
        map = {
          ...map,
          [key]: {
            ...validityMap[key],
            valid: false,
          },
        };
      else {
        map = {
          ...map,
          [key]: validityMap[key],
        };
      }
    });
    setValidityMap(map);
  };

  const onChange = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      name,
      value,
      validity: { valid },
    } = e.target;

    setValidityMap({
      ...validityMap,
      [name]: {
        valid,
        value,
      },
    });
  };

  return {
    validityMap,
    checkInputs,
    onChange,
  };
};

export default useFormValidation;
